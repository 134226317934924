<template>
	<div style="margin-top: 20px;">
		<van-loading vertical color="#1989fa">
		  <template #icon>
		    <van-icon name="star-o" size="30" />
		  </template>
		  加载中...
		</van-loading>
	</div>
</template>

<script>
	import request from "@/api/index.js";
  import {appId, SystemName} from '@/settings.js'
	// 判断是否为微信环境
	const isWechat = () => {
		return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
	}
	export default {
		name: '',
		components: {
			
		},
		props: {},
		data() {
			return {
				code: '',
				openId: '',
				appId: appId,
        appSaasId:'',
        sysName:SystemName,
        orgId:'',
        token:'',
        tenantCode:'',
			}
		},
		computed: {
		},
		watch: {
		},
		created() {
      if (this.sysName =='saas'){
        let params = this.$route.query;
        let tenantCode = params.tenantCode
        if (tenantCode || tenantCode == 0){
          this.tenantCode =tenantCode
          localStorage.setItem("tenantCode",this.tenantCode)
        }
        let appSaasId = params.appId
        if (appSaasId){
          this.appSaasId =appSaasId
          localStorage.setItem("appSaasId",this.appSaasId)
        }
        let orgId = params.orgId;
        if (orgId || orgId == 0){
          this.orgId = orgId
          localStorage.setItem("orgId",this.orgId)
        }
      }
			this.getWxCode();
			if(this.code != ''){
				if (this.sysName === 'saas'){
          this.getTokens();
        }else {
          this.getToken();
        }
			}
		},
		mounted() {
	
		},
		methods: {
      getTokens(){
        let data
        if (this.sysName === 'saas'){
          data ={code:this.code,tenantCode:this.tenantCode?this.tenantCode:localStorage.getItem("tenantCode"),orgId:this.orgId?this.orgId:localStorage.getItem("orgId")}
        }else {
          {code:this.code}
        }
        request.get('/pay/waterFee/token',{params:data}).then(res=>{
          this.token=res.data
          localStorage.setItem("orderToken",this.token)
          this.getToken()
        }).catch(err=>{
          alert(err.message)
        })
      },
			getToken(){
			  request.get('/wechat/publicAccount/checkWechatOpenIdBindWaterCodeAndUserName',{ params: {code: this.code,token:this.token?this.token:localStorage.getItem("orderToken")} }).then(res=>{
				if(res.code == 200){
					this.$router.push({path: '/zsBindWaterCodeSuccess', query: {openId: res.data}})
				}else if(res.code == 500){
					this.$router.push({path: '/bindWechatOpenIdWithWaterCode', query: {openId: res.data}})
				}else if(res.code == 501){
					this.$toast.fail(res.msg)
				}
				}).catch(err=>{
					this.$toast.fail(err.message)
				})
			},
			getWxCode() {
				if (isWechat) {
					let code = this.getUrlCode().code; //是否存在code
          if (this.sysName =='saas') {
            let tenantCode = this.getUrlCode().tenantCode
            if (tenantCode || tenantCode == 0) {
              this.tenantCode = tenantCode
              localStorage.setItem("tenantCode", this.tenantCode)
            }
            let appSaasId = this.getUrlCode().appId
            if (appSaasId) {
              this.appSaasId = appSaasId
              localStorage.setItem("appSaasId", this.appSaasId)
            }
            let orgId = this.getUrlCode().orgId;
            if (orgId || orgId == 0){
              this.orgId = orgId
              localStorage.setItem("orgId",this.orgId)
            }
          }
					let local = window.location.href;
					if (code == null || code === "") {
            let appIds = this.sysName==='saas' ? (this.appSaasId ? this.appSaasId : localStorage.getItem("appSaasId")) : this.appId
					  //不存在就打开上面的地址进行授权
					  window.location.href =
						"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                appIds +
						"&redirect_uri=" +
						encodeURIComponent(local) +
						"&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
					} else {
						this.code = code;
					}
				}
			},
			getUrlCode() {
				// 截取url中的code方法
				var url = location.search;
				var theRequest = new Object();
				if (url.indexOf("?") != -1) {
					var str = url.substr(1);
					var strs = str.split("&");
					for (var i = 0; i < strs.length; i++) {
						theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
					}
				}
				return theRequest;
			},
		},
	}
</script>

<style>
</style>